import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAllProjects, deleteProject } from '../../actions/projectActions'

import { Card, Button, Row, Image } from 'react-bootstrap'

import { setUploadedSubtitles } from '../../actions/uploadedSubtitlesActions'
import { setTranslatedSubtitles } from '../../actions/translatedSubtitlesActions'
import { setSubtitleProperties } from '../../actions/subtitlePropertiesActions'
import { setCurrentProjectForRecordings } from '../../actions/recordingActions'
import { setCurrentProjectForSnapshots } from '../../actions/snapshotActions'
import { setCurrentProject } from '../../actions/currentProjectAction'
import { toggleSort } from '../../actions/settingsActions'

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css';
import './projects.css'
import ModalAddProject from './modalAddProject/modalAddProject'
import EditProject from './editProject/editProject'

import Swal from 'sweetalert2'
import coming_soon from '../../images/cooming_soon.svg'
import empty_list from '../../images/empty_list.svg'
import { apiUrl } from '../../constants'

import { Modal } from 'react-bootstrap'

import WysiEditor from '../adminPanel/adminAddProject/wysiEditor'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import AdminProjectList from '../adminPanel/adminProjectList/adminProjectList'

const MY_PROJECTS = "MY_PROJECTS"
const ADMIN_PROJECTS = "ADMIN_PROJECTS"

const Projects = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [showModalAddProject, setShowModalAddProject] = useState(false)
    const [showProjects, setShowProjects] = useState(MY_PROJECTS)
    const [emptyProjectList, setEmptyProjectList] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editingProject, setEditingProject] = useState({})

    const sort = useSelector(state => state.settingsReducer.sort)
    const [currentSort, setCurrentSort] = useState(sort)

    const theme = useSelector(state => state.settingsReducer.theme)
    const userProjects = useSelector(state => state.projectReducer.projects)
    const isLogged = useSelector(state => state.authorizationReducer.isLogged)

    const projectChosen = (project) => {
        console.log(project);
        dispatch(setUploadedSubtitles(project.uploaded_subtitles))
        dispatch(setTranslatedSubtitles(project.translated_subtitles))
        dispatch(setSubtitleProperties({
            subsTitle: project.subtitle_name,
            videoUrl: project.videoUrl,
            lastEdited: project.lastEdited
        }))
        dispatch(setCurrentProject(project))
        history.push('/')
    }

    const handleDeleteProject = (project) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deleteProject(project))
                    .then(res => {
                        dispatch(getAllProjects())
                            .then(res => {
                                if (res.data.projects.length == 0) {
                                    setEmptyProjectList(true)
                                } else {
                                    setEmptyProjectList(false)
                                }
                            })
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(err => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: err,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
        })
    }

    const handleLoadingProjects = (sort = '{"updatedAt": "asc"}') => {
        if (isLogged) {
            dispatch(getAllProjects(sort))
                .then(res => {
                    if (res.data.projects.length == 0) {
                        setEmptyProjectList(true)
                    } else {
                        var filtered = res.data.projects.filter(proj => proj?.studentFlag == true)
                        if (filtered.length == res.data.projects.length) {
                            setEmptyProjectList(true)
                        } else {
                            setEmptyProjectList(false)
                        }
                    }
                })
        } else {
            history.push('/login')
        }
    }

    const editProject = (project) => {
        setEditingProject(project)
        setIsEditing(true)
    }

    const sortHandler = (value) => {
        setCurrentSort(value);
        dispatch(toggleSort(value));
    }

    useEffect(() => {
        setIsEditing(false)
        // handleLoadingProjects()
    }, [])

    useEffect(() => {
        handleLoadingProjects(currentSort);
    }, [currentSort])

    return (
        <div className="projects-main" style={{ display: "inline-flex", backgroundColor: theme == 'Dark' ? '#121212' : '#ffffff', color: theme == 'Dark' ? '#ffffff' : '#121212' }}>
            <ModalAddProject
                showModalAddProject={showModalAddProject}
                setShowModalAddProject={setShowModalAddProject}
            ></ModalAddProject>
            <div className="sidebar" style={{ display: "inline-block" }}>
                <ProSidebar
                    collapsed={false}
                >
                    <Menu iconShape="square">
                        <MenuItem><Button onClick={(() => setShowModalAddProject(true))}>New +</Button></MenuItem>
                        {/* <SubMenu title="Components"> */}
                        <MenuItem onClick={() => {
                            setShowProjects(MY_PROJECTS)
                            setIsEditing(false)
                        }}>My projects</MenuItem>
                        <MenuItem onClick={() => {
                            // setShowProjects(ADMIN_PROJECTS)
                            history.push({
                                pathname: '/admin-panel',
                                state: {
                                    showProjects: true
                                }
                            })
                            setIsEditing(false)
                        }}>Student projects</MenuItem>
                        {/* </SubMenu> */}
                        <select
                            className="select-input"
                            value={currentSort}
                            onChange={(e) => sortHandler(e.target.value)}
                        >
                            <option value='{"updatedAt": "asc"}'>Order by date &uarr;</option>
                            <option value='{"updatedAt": "desc"}'>Order by date &darr;</option>
                            <option value='{"subtitle_name": "asc"}'>Order by name &uarr;</option>
                            <option value='{"subtitle_name": "desc"}'>Order by name &darr;</option>
                        </select>
                    </Menu>
                </ProSidebar>
            </div>
            {
                isEditing ? (
                    <EditProject
                        editingProject={editingProject}
                        setIsEditing={setIsEditing}
                    ></EditProject>
                ) : (
                    <>
                        {
                            showProjects == MY_PROJECTS && (
                                <MyProjectList
                                    setShowModalAddProject={setShowModalAddProject}
                                    emptyProjectList={emptyProjectList}
                                    userProjects={userProjects}
                                    projectChosen={projectChosen}
                                    handleDeleteProject={handleDeleteProject}
                                    editProject={editProject}
                                    theme={theme}
                                    dispatch={dispatch}
                                    history={history}
                                />
                            )

                        }
                    </>
                )
            }
        </div>
    )
}

const MyProjectList = ({
    setShowModalAddProject,
    emptyProjectList,
    userProjects,
    projectChosen,
    handleDeleteProject,
    editProject,
    theme,
    dispatch,
    history
}) => {
    const [showReadMeModal, setShowReadMeModal] = useState(false)
    const [readMeProject, setReadMeProject] = useState("")

    const showRecordings = (project) => {
        dispatch(setCurrentProjectForRecordings(project._id))
        history.push('/recordings')
    }

    const showSnapshots = (project) => {
        dispatch(setCurrentProjectForSnapshots(project._id))
        history.push('/snapshots');
    }

    const handleShowModalReadMe = (project) => {
        setShowReadMeModal(true)
        setReadMeProject(project.readMe)
    }

    return (
        emptyProjectList ? (
            <div className="div-coming-soon">
                <div className="centered">No projects</div>
                <Image className="coming-soon-img" src={empty_list} width="40%" height="40%"></Image>
                <Button className="add-new-btn" type="primary" onClick={() => setShowModalAddProject(true)}>Add new +</Button>
            </div>
        ) : (
            <Row>
                <ReadMeModal showReadMeModal={showReadMeModal} setShowReadMeModal={setShowReadMeModal} readMeProject={readMeProject} />
                {userProjects && userProjects.map(project => {
                    return (
                        !project.studentFlag && (
                            <Card style={{ width: '18rem', height: 'fit-content', color: 'black' }} key={project.subtitle_name}>
                                {
                                    !project.privateFlag && (
                                        <span className="student-cap-icon">🎓</span>
                                    )
                                }
                                <Card.Img variant="top" src={`${apiUrl}/${project.thumbnail}`} style={{ objectFit: "contain" }} />
                                <Card.Body>
                                    <Card.Title>{project.subtitle_name}</Card.Title>
                                    <Card.Text>
                                        Created at: {project.createdAt.split('T')[0] + " " + project.createdAt.split('T')[1].slice(0, 8)}
                                    </Card.Text>
                                    <Card.Text>
                                        Updated at: {project.updatedAt.split('T')[0] + " " + project.updatedAt.split('T')[1].slice(0, 8)}
                                    </Card.Text>
                                    <div className="div-card-button-wrapper">
                                        <Button className="card-button" variant="primary" onClick={() => projectChosen(project)}>Load</Button>
                                        {
                                            project.deletePermission && (
                                                <>
                                                    <Button className="card-button" variant="success" onClick={() => editProject(project)}>Edit</Button>
                                                    <Button className="card-button" variant="danger" onClick={() => handleDeleteProject(project)}>Delete</Button>
                                                </>
                                            )
                                        }
                                        <Button variant="info" onClick={() => showRecordings(project)}>Recordings</Button>
                                        {
                                            project.readMe && (
                                                <Button variant="warning" onClick={() => handleShowModalReadMe(project)}>ReadMe</Button>
                                            )
                                        }
                                        <Button variant="info" onClick={() => showSnapshots(project)}>Snapshots</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    )
                })}
            </Row>
        )
    )
}

const ReadMeModal = ({ showReadMeModal, setShowReadMeModal, readMeProject }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorContent, setEditorContent] = useState("")
    useEffect(() => {
        console.log(readMeProject)
        if (readMeProject == "" || readMeProject == undefined) {
            return
        } else {
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(readMeProject))))
        }
    }, [readMeProject])
    return (
        <div className="modal-read-me">
            <Modal show={showReadMeModal} onHide={() => setShowReadMeModal(false)} className="read-me-modal" backdrop={true}>
                <Modal.Header closeButton onClick={() => setShowReadMeModal(false)}>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <WysiEditor
                        editorState={editorState}
                        setEditorState={setEditorState}
                        setEditorContent={setEditorContent}
                        readOnly={true}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReadMeModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Projects