import axios from 'axios';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import React, { useEffect, useState } from 'react';
import { Button, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { loginUser, logoutUser } from '../../actions/authActions';
import { changeSiteLanguage, LS_SITE_LANGUAGE, toggleKeyboardPop, toggleSidebar } from '../../actions/settingsActions';
import { apiUrl, Desktop, TabletAndBelow } from '../../constants';
import i18n from '../../i18n';
import croatia from '../../images/croatia.svg';
import hamburgerMenu from '../../images/Hamburger_icon.svg';
import ukflag from '../../images/united-kingdom.svg';
import ModalDictionaryWord from './addingWords/dictionaryWord';
import ModalPhraseWord from './addingWords/phraseWord';
import ModalGvtSettings from './modalGvtSettings/modalGvtSettings';
import ModalSettings from './modalSettings/modalSettings';
import "./navbar.css";






function NavBar({ t }) {
    let history = useHistory()

    const [showLogin, setShowLogin] = useState(false);
    const [validated, setValidated] = useState(false);

    const keyboardPop = useSelector(state => state.settingsReducer.keyboardPop)
    const showSidebar = useSelector(state => state.settingsReducer.showSidebar)

    const [showProjectHome, setShowProjectHome] = useState(false)

    const [showSettings, setShowSettings] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const [showGvtSettings, setShowGvtSettings] = useState(false);
    const [showAddPhraseWord, setShowAddPhraseWord] = useState(false);
    const [showDictionaryWord, setShowDictionaryWord] = useState(false);
    const [theme, setTheme] = useState('light')
    const [currentLanguage, setCurrentLanguage] = useState("hr")

    let uploadedSubtitles = useSelector(state => state.uploadedSubtitlesReducer.uploadedSubtitles)
    let subtitleName = useSelector(state => state.subtitlePropertiesReducer.subsTitle)

    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const authData = useSelector(state => state.authorizationReducer)

    const setLanguage = (lng) => {
        setCurrentLanguage(lng)
        changeSiteLanguage(lng);
        i18n.changeLanguage(lng);
    }

    const handleShowSettings = () => setShowSettings(true);
    const handleCloseSettings = () => {
        setShowSettings(false)
    }

    const handleShowAddPhraseWord = () => setShowAddPhraseWord(true);
    const handleCloseAddPhraseWord = () => {
        setShowAddPhraseWord(false)
    }

    const handleShowGvtSettings = () => setShowGvtSettings(true)
    const handleCloseGvtSettings = () => {
        setShowGvtSettings(false)
    }

    const handleShowDictionaryWord = () => setShowDictionaryWord(true)
    const handleCloseDictionaryWord = () => {
        setShowDictionaryWord(false)
    }

    const handleRedirect = (path) => {
        history.push(`/${path}`)
    }

    const changeTheme = () => {
        dispatch({
            type: 'CHANGE_THEME',
            payload: theme == 'Dark' ? 'Light' : 'Dark'
        })
        setTheme(theme == 'Dark' ? 'Light' : 'Dark')
    }

    const handleLogout = () => {
        dispatch(logoutUser())
            .then(() => {
                history.push('/login')
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong, try again!',
                })
            })
    }

    const handleAdminPanel = () => {
        if (authData.isAdmin) {
            history.push('/admin-panel')
        }
    }

    const handleModeratorPanel = () => {
        history.push('/moderator-panel')
    }

    const handleRedirectsOnInitialLoad = () => {
        axios.post(`${apiUrl}/auth/verify-auth`, {})
            .then(res => {
                dispatch(loginUser(res.data))
                // if(!history.location.pathname.includes("admin-panel")) {
                //     history.push('/projects')
                // }
            })
            .catch(err => {
                if (!history.location.pathname.includes("registration")) {
                    history.push('/login')
                }
                dispatch({
                    type: "STOP_LOADING"
                })
            })
    }

    useEffect(() => {
        const language = localStorage.getItem(LS_SITE_LANGUAGE);
        if (language) {
            setLanguage(JSON.parse(language))
        }
        handleRedirectsOnInitialLoad()
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            if (location.pathname != "/") {
                setShowProjectHome(true)
            } else {
                setShowProjectHome(false)
            }
        })
    }, [history])

    // useEffect(() => {
    //     window.addEventListener('resize', ({ currentTarget }) => {
    //     })
    // }, [])

    if (isLoading) {
        return <div className="spinner"></div>
    }

    return (
        <>
            <Desktop>
                <div>
                    <Navbar bg="light" expand="lg" fixed="top">
                        {isMobile && <a onClick={() => dispatch(toggleSidebar(!showSidebar))}><Image src={hamburgerMenu} height="20px" width="20px"></Image></a>}
                        <Navbar.Brand onClick={() => handleRedirect("")}>Speak-Subz</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        {showProjectHome && authData.isLogged && subtitleName && <Button variant="secondary" onClick={() => handleRedirect("")}>Back to project</Button>}
                        {!showProjectHome && authData.isLogged && isMobile &&
                            <div className="keyboard-div-btn" style={{ paddingTop: '10px' }}>
                                <span>Keyboard:</span>
                                <BootstrapSwitchButton
                                    checked={keyboardPop}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    className="btn-keyboard-toggle"
                                    onChange={() => {
                                        dispatch(toggleKeyboardPop(!keyboardPop))
                                    }}></BootstrapSwitchButton>
                            </div>
                        }
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className="mr-sm-2">
                                {authData.isLogged &&
                                    <React.Fragment>
                                        <Nav.Link onClick={() => handleRedirect("projects")}>{t('nav-projects')}</Nav.Link>
                                        {/* <Nav.Link onClick={() => handleShowGvtSettings()}>{t('nav-gvt-key')}</Nav.Link> */}
                                        <NavDropdown title={t('nav-dictionary')} id="basic-nav-dropdown">
                                            <NavDropdown.Item onClick={() => handleRedirect("dict")}>{t('nav-edit')}</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowAddPhraseWord()}>{t('nav-phrases')}</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowDictionaryWord()}>{t('nav-dict-word')}</NavDropdown.Item>
                                        </NavDropdown>
                                        {/* <NavDropdown.Item onClick={() => handleRedirect("dict")}>{t('nav-dictionary')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleShowAddPhraseWord()}>{t('nav-phrases')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleShowDictionaryWord()}>{t('nav-dict-word')}</NavDropdown.Item> */}
                                        <Nav.Link onClick={() => handleShowSettings()}>{t('nav-settings')}</Nav.Link>
                                        <Nav.Link onClick={() => handleLogout()}>{t('nav-logout')}</Nav.Link>
                                        {
                                            !authData.isAdmin && (
                                                <React.Fragment>
                                                    <Nav.Link onClick={() => handleModeratorPanel()}>{t('nav-moderator')}</Nav.Link>
                                                    {/* <Nav.Link onClick={() => history.push('/registration')}>{t('nav-registration')}</Nav.Link> */}
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            authData.isAdmin && (
                                                <React.Fragment>
                                                    <Nav.Link onClick={() => handleAdminPanel()}>{t('nav-admin')}</Nav.Link>
                                                    {/* <Nav.Link onClick={() => history.push('/registration')}>{t('nav-registration')}</Nav.Link> */}
                                                </React.Fragment>
                                            )
                                        }
                                        <span className="nav_username">{authData.username}</span>
                                    </React.Fragment>
                                }
                                {!authData.isLogged &&
                                    <React.Fragment>
                                        <Nav.Link onClick={() => history.push('/login')}>{t('nav-login')}</Nav.Link>
                                    </React.Fragment>
                                }
                                <Nav.Link onClick={() => setLanguage("hr")}><Image className={currentLanguage == "en" ? "lng-flags not-active-flag" : "lng-flags "} src={croatia} height="20px" width="20px"></Image></Nav.Link>
                                <Nav.Link onClick={() => setLanguage("en")}><Image className={currentLanguage == "hr" ? "lng-flags not-active-flag" : "lng-flags "} src={ukflag} height="20px" width="20px"></Image></Nav.Link>
                                {/* <Nav.Link onClick={() => changeTheme()}>
                        { theme == 'Dark' ? "Light" : "Dark"}
                    </Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <ModalSettings
                        showSettings={showSettings}
                        handleCloseSettings={handleCloseSettings}
                    />
                    <ModalPhraseWord
                        showAddPhraseWord={showAddPhraseWord}
                        handleCloseAddPhraseWord={handleCloseAddPhraseWord}
                    >
                    </ModalPhraseWord>
                    <ModalGvtSettings
                        showGvtSettings={showGvtSettings}
                        handleCloseGvtSettings={handleCloseGvtSettings}
                    >
                    </ModalGvtSettings>
                    <ModalDictionaryWord
                        showDictionaryWord={showDictionaryWord}
                        handleCloseDictionaryWord={handleCloseDictionaryWord}
                    ></ModalDictionaryWord>
                </div>

            </Desktop>
            <TabletAndBelow>
                <div>
                    <Navbar bg="light" expand="xs" fixed="top">
                        <a onClick={() => dispatch(toggleSidebar(!showSidebar))}><Image src={hamburgerMenu} height="20px" width="20px"></Image></a>
                        <Navbar.Brand onClick={() => handleRedirect("")}>Speak-Subz</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        {showProjectHome && authData.isLogged && subtitleName && <Button variant="secondary" onClick={() => handleRedirect("")}>Back to project</Button>}
                        {!showProjectHome && authData.isLogged && isMobile &&
                            <div className="keyboard-div-btn" style={{ paddingTop: '10px' }}>
                                <span>Keyboard:</span>
                                <BootstrapSwitchButton
                                    checked={keyboardPop}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    className="btn-keyboard-toggle"
                                    onChange={() => {
                                        dispatch(toggleKeyboardPop(!keyboardPop))
                                    }}></BootstrapSwitchButton>
                            </div>
                        }
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className="mr-sm-2">
                                {authData.isLogged &&
                                    <React.Fragment>
                                        <Nav.Link onClick={() => handleRedirect("projects")}>{t('nav-projects')}</Nav.Link>
                                        {/* <Nav.Link onClick={() => handleShowGvtSettings()}>{t('nav-gvt-key')}</Nav.Link> */}
                                        <NavDropdown title={t('nav-dictionary')} id="basic-nav-dropdown">
                                            <NavDropdown.Item onClick={() => handleRedirect("dict")}>{t('nav-edit')}</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowAddPhraseWord()}>{t('nav-phrases')}</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => handleShowDictionaryWord()}>{t('nav-dict-word')}</NavDropdown.Item>
                                        </NavDropdown>
                                        {/* <NavDropdown.Item onClick={() => handleRedirect("dict")}>{t('nav-dictionary')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleShowAddPhraseWord()}>{t('nav-phrases')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleShowDictionaryWord()}>{t('nav-dict-word')}</NavDropdown.Item> */}
                                        <Nav.Link onClick={() => handleShowSettings()}>{t('nav-settings')}</Nav.Link>
                                        <Nav.Link onClick={() => handleLogout()}>{t('nav-logout')}</Nav.Link>
                                          {
                                            !authData.isAdmin && authData.moderatedGroups && authData.moderatedGroups.length > 0 && (
                                                <React.Fragment>
                                                    <Nav.Link onClick={() => handleModeratorPanel()}>{t('nav-moderator')}</Nav.Link>
                                                    {/* <Nav.Link onClick={() => history.push('/registration')}>{t('nav-registration')}</Nav.Link> */}
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            authData.isAdmin && (
                                                <React.Fragment>
                                                    <Nav.Link onClick={() => handleAdminPanel()}>{t('nav-admin')}</Nav.Link>
                                                    {/* <Nav.Link onClick={() => history.push('/registration')}>{t('nav-registration')}</Nav.Link> */}
                                                </React.Fragment>
                                            )
                                        }
                                        <span className="nav_username">{authData.username}</span>
                                    </React.Fragment>
                                }
                                {!authData.isLogged &&
                                    <React.Fragment>
                                        <Nav.Link onClick={() => history.push('/login')}>{t('nav-login')}</Nav.Link>
                                    </React.Fragment>
                                }
                                <Nav.Link onClick={() => setLanguage("hr")}><Image className={currentLanguage == "en" ? "lng-flags not-active-flag" : "lng-flags "} src={croatia} height="20px" width="20px"></Image></Nav.Link>
                                <Nav.Link onClick={() => setLanguage("en")}><Image className={currentLanguage == "hr" ? "lng-flags not-active-flag" : "lng-flags "} src={ukflag} height="20px" width="20px"></Image></Nav.Link>
                                {/* <Nav.Link onClick={() => changeTheme()}>
                        { theme == 'Dark' ? "Light" : "Dark"}
                    </Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <ModalSettings
                        showSettings={showSettings}
                        handleCloseSettings={handleCloseSettings}
                    />
                    <ModalPhraseWord
                        showAddPhraseWord={showAddPhraseWord}
                        handleCloseAddPhraseWord={handleCloseAddPhraseWord}
                    >
                    </ModalPhraseWord>
                    <ModalGvtSettings
                        showGvtSettings={showGvtSettings}
                        handleCloseGvtSettings={handleCloseGvtSettings}
                    >
                    </ModalGvtSettings>
                    <ModalDictionaryWord
                        showDictionaryWord={showDictionaryWord}
                        handleCloseDictionaryWord={handleCloseDictionaryWord}
                    ></ModalDictionaryWord>
                </div>
            </TabletAndBelow>
        </>
    )
}

export default withNamespaces()(NavBar)
