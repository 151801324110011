import { SET_LAST_EDITED, SET_SUBTITLE_PROPERTIES, SET_CAPITAL_LETTER, SET_FIRST_SENTENCE, SET_LAST_EDITED_VALUE, SET_LAST_EDITED_COMMENT_ID, SET_LAST_EDITED_COMMENT_VALUE } from "./types"

export const setSubtitleProperties = (props, saveToLocalStorage = true) => {
    if (saveToLocalStorage) {
        saveChosenProjectToLocalStorage(props.subsTitle)
    }
    return {
        type: SET_SUBTITLE_PROPERTIES,
        payload: props
    }
}

const saveChosenProjectToLocalStorage = (title) => {
    localStorage.setItem("project-name", title)
}

export const setLastEdited = (position) => {
    return {
        type: SET_LAST_EDITED,
        payload: position
    }
}

export const setLastEditedValue = (value) => {
    return {
        type: SET_LAST_EDITED_VALUE,
        payload: value
    }
}

export const setLastEditedCommentId = (value) => {
    return {
        type: SET_LAST_EDITED_COMMENT_ID,
        payload: value
    }
}

export const setLastEditedCommentValue = (value) => {
    return {
        type: SET_LAST_EDITED_COMMENT_VALUE,
        payload: value
    }
}

export const setFirstSentence = (sentence) => {
    return {
        type: SET_FIRST_SENTENCE,
        payload: sentence
    }
}

export const setCapitalLetter = (status) => {
    return {
        type: SET_CAPITAL_LETTER,
        payload: status
    }
}