import { SET_SUBTITLE_PROPERTIES, CLEAR_SUBTITLE_PROPERTIES, SET_LAST_EDITED, SET_FIRST_SENTENCE, SET_CAPITAL_LETTER, SET_LAST_EDITED_VALUE, SET_LAST_EDITED_COMMENT_ID, SET_LAST_EDITED_COMMENT_VALUE } from "../actions/types"

const initialState = {
    url: "",
    lang: 'en',
    subsTitle: "",
    videoUrl: "",
    lastEdited: 1,
    lastEditedValue: "",
    lastEditedCommentId: null,
    lastEditedCommentValue: "",
    firstSentence: "",
    capitalLetter: false
}

function subtitlePropertiesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SUBTITLE_PROPERTIES:
            return {
                ...state,
                url: action.payload.url,
                subsTitle: action.payload.subsTitle,
                videoUrl: action.payload.videoUrl,
                lastEdited: action.payload.lastEdited
            }
        case CLEAR_SUBTITLE_PROPERTIES:
            return {
                ...state,
                url: "",
                subsTitle: "",
                videoUrl: "",
            }
        case SET_LAST_EDITED_COMMENT_ID:
            return {
                ...state,
                lastEditedCommentId: action.payload
            }
        case  SET_LAST_EDITED_COMMENT_VALUE:
            console.log(`Reducing last edited comment value: ${action.payload}`)
            return {
                ...state,
                lastEditedCommentValue: action.payload
            }
        case SET_LAST_EDITED:
            return {
                ...state,
                lastEdited: action.payload
            }
        case SET_LAST_EDITED_VALUE:
            return {
                ...state,
                lastEditedValue: action.payload
            }
        case SET_FIRST_SENTENCE:
            return {
                ...state,
                firstSentence: action.payload
            }
        case SET_CAPITAL_LETTER:
            return {
                ...state,
                capitalLetter: action.payload
            }
        default:
            return state
    }
}

export default subtitlePropertiesReducer