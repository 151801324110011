import axios from 'axios'
import { apiUrl } from '../constants'
import { SET_USER_PROJECTS } from './types'

export const getAllProjects = (sort) => dispatch => {
    console.log(sort)
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/projects/get_projects`, { sort })
            .then(res => {
                console.log(res)
                dispatch({
                    type: SET_USER_PROJECTS,
                    payload: res.data.projects
                })
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const deleteProject = (project) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/projects/delete_project`, {
            project
        })
            .then(res => {
                resolve(res.data.msg)
            })
            .catch(err => {
                reject(err?.response?.data?.msg ?? "Server error!")
            })
    })
}

export const updateProjectChanges = (formData) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/projects/update_project`,
            formData
        )
            .then(res => {
                resolve(res.data.msg)
            })
            .catch(err => {
                reject(err?.response?.data?.msg ?? "Server error!")
            })
    })
}