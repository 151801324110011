import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './subtitleRow.css'
import { saveProcess } from '../../../actions/savingActions'
import { addCommentToUploadedSubtitle } from '../../../actions/uploadedSubtitlesActions'
import { isMobile } from 'react-device-detect'

const UploadedSubtitleCell = ({
    uploadedSubId,
    uploadedSubText,
    uploadedSubStart,
    uploadedSubEnd,
    uploadedSubComment
}) => {
    const editFontSize = useSelector(state => state.settingsReducer.editFontSize)

    const getSecondsFromTimestamp = (timestamp) => {
        return new Date(timestamp * 1000).toISOString().substr(11, 12)
    }

    return (
        <div className="subtitle-cell-wrapper">
            <div className="subtitle-cell-item subtitle-cell-item-color">
                <div className="subtitle-info-timestamp">
                    <div className="subtitle-info-i sub-id">{uploadedSubId}</div>
                    <div className="subtitle-info-i sub-end">{getSecondsFromTimestamp(uploadedSubStart)}</div>
                    <div className="subtitle-info-i sub-end">{getSecondsFromTimestamp(uploadedSubEnd)}</div>
                </div>
                {isMobile &&
                    <div className='mobile-sub-padding'></div>
                }
                <div style={{ fontSize: `${editFontSize}px`, marginTop: '4px' }}>
                    <textarea
                        disabled
                        value={uploadedSubText}
                        className="subtitle-text"
                        rows="2">
                    </textarea>
                </div>
                <div className="subtitle-info-counters">
                    <p>Word count: {uploadedSubText.split(/\s+/).length}</p>
                </div>
            </div>
            <SubtitleComment
                uploadedSubId={uploadedSubId}
                uploadedSubComment={uploadedSubComment}
            />
        </div>
    )
}

export default UploadedSubtitleCell

const SubtitleComment = ({
    uploadedSubId,
    uploadedSubComment
}) => {
    const dispatch = useDispatch()
    const [comment, setComment] = useState("")
    const autoSave = useSelector(state => state.settingsReducer.autoSave)
    const currentProject = useSelector(state => state.currentProjectReducer.project)

    const textAreaLeave = () => {
        console.log(uploadedSubId, comment)
        dispatch(addCommentToUploadedSubtitle(uploadedSubId, comment))
        if (autoSave) {
            dispatch(saveProcess("", "", ""))
        }
    }

    useEffect(() => {
        setComment(uploadedSubComment)
    }, [])

    return (
        <div>
            <p className="formfield">
                {/* <label for="comment">Comment</label> */}
                <textarea disabled={currentProject?.isSnapshot ? true : false} tabIndex="-1" id="comment" placeholder="Enter comment..." value={comment} className="comment-area comment-uploaded" onChange={(e) => setComment(e.target.value)} onBlur={textAreaLeave}></textarea>
            </p>
        </div>
    )
}